import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { routes } from '../../../services/navigation/navigation.service.routes';
import useUserStateChange from '../../../hook/useUserStateChange';
import {
  showCompliancePage,
  showHelpPage,
  showJoinNowPage,
  showJustAdded,
  showMyAccountPage,
  showOriginalsPage,
  showPrivacyPolicyPage,
  showTermsOfUsePage,
  showTop10,
  showTrentonDucatiOriginalsRoute,
} from '../../../services/navigation/navigation.service';
import { getMiddleSectionHeight, isExternalLink, openNewBrowserTab } from '../../../services/util-service/util.service';
import { ModalController } from '../../../controllers/modal-controller/modal.controller';
import { UsersController } from '../../../controllers/users-controller/users.controller';
import { links } from '../../../links';
import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';

import './SideMenu.scss';
import { env } from '../../../env';
import { RenderConditionalComponent, PROPERTY_ID } from '../../RenderConditionalComponent/RenderConditionalComponent';
import { DashboardController } from '../../../controllers/dashboard-controller/DashboardController';

const SideMenu = (props) => {
  const { browse_config = [], stars_page_enabled } = DashboardController.getWhitelabelConfig();
  const { t } = useTranslation();
  const [logged] = useUserStateChange();

  const getSection0Items = () => {
    return [
      {
        key: 'tdoriginals',
        label: t('MainDashboardHeader.tdoriginals'),
        onClick: showTrentonDucatiOriginalsRoute,
      },
      {
        key: 'nsoriginals',
        label: t('MainDashboardHeader.nsoriginals'),
        onClick: showOriginalsPage,
      },
    ];
  };

  const getSection1Items = () => {
    const mobileNavigationSection = [
      {
        key: 'justAdded',
        label: t('SideMenu.justAdded'),
        onClick: showJustAdded,
      },
      {
        key: 'top10',
        label: t('SideMenu.top10'),
        onClick: showTop10,
      },
      {
        key: 'originals',
        label: (
          <>
            <RenderConditionalComponent
              ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn, PROPERTY_ID.huntForMen]}
            >
              {t('MainDashboardHeader.originals')}
            </RenderConditionalComponent>
          </>
        ),
        onClick: showOriginalsPage,
      },
      {
        key: 'live',
        label: t('SideMenu.live'),
        onClick: openNewBrowserTab.bind(this, links.live),
      },
    ];

    return mobileNavigationSection;
  };

  const navigateToPage = (link) => {
    navigate(link);
  };

  const getSection2Items = () => {
    const items = browse_config.reduce(
      (config, { id, link, text, open_in_new_window, logged_in = true, logged_out = true }) => {
        const isExternal = isExternalLink(link);
        let fullLink = link.startsWith('/') ? link : `/${link}`;
        if (isExternal) {
          fullLink = link;
        }
        let item = {
          label: text,
          key: `${id}-${text}`,
          onClick:
            open_in_new_window || isExternal
              ? openNewBrowserTab.bind(this, fullLink)
              : navigateToPage.bind(this, fullLink),
        };

        const isStarsPage = fullLink === routes.stars;
        if (isStarsPage && !stars_page_enabled) {
          return config;
        }

        if (logged && logged_in) {
          config.push(item); // display to the logged user
        } else if (!logged && logged_out) {
          config.push(item); // display to the non logged user
        }
        return config;
      },
      [],
    );
    return items;
  };

  const getSection3Items = () => {
    return [
      {
        key: 'newsletter',
        label: t('SideMenu.newsletter'),
        onClick: showNewsLetterDialog,
      },
      {
        key: 'twitter',
        label: t('SideMenu.twitter'),
        onClick: openNewBrowserTab.bind(this, links.twitter),
      },
    ];
  };

  const getSection4Items = () => {
    const items =
      env.REACT_APP_DISPLAY_AFFILIATE_PROGRAM === 'on'
        ? [
          {
            key: 'webmasters',
            label: t('SideMenu.webmasters'),
            onClick: openNewBrowserTab.bind(this, links.webmasters),
          },
        ]
        : [];
    return items.concat(
      {
        key: 'billingSupport',
        label: t('SideMenu.billingSupport'),
        onClick: showHelpPage,
        mailto: links.billingAndSupport,
      },
      {
        key: 'help',
        label: t('SideMenu.help'),
        onClick: showHelpPage,
      },
    );
  };

  const getSection5Items = () => {
    return [
      {
        key: '2257Compliance',
        label: t('SideMenu.2257Compliance'),
        onClick: showCompliancePage,
      },
      {
        key: 'termsOfUse',
        label: t('SideMenu.termsOfUse'),
        onClick: showTermsOfUsePage,
      },
      {
        key: 'privacyPolicy',
        label: t('SideMenu.privacyPolicy'),
        onClick: showPrivacyPolicyPage,
      },
    ];
  };

  const getSection6Items = () => {
    return logged
      ? [
        {
          key: 'myAccount',
          label: t('SideMenu.myAccount'),
          onClick: showMyAccountPage,
        },
        {
          key: 'signOut',
          label: t('SideMenu.signOut'),
          onClick: signOutUser,
        },
      ]
      : [
        {
          key: 'joinNow',
          label: t('SideMenu.joinNow'),
          onClick: showJoinNowPage,
        },
        {
          key: 'signIn',
          label: t('SideMenu.signIn'),
          onClick: showSignInDialog,
        },
      ];
  };

  const showSignInDialog = () => {
    SignInController.showSignInDialog(true);
  };

  const onItemClick = (onClick, closeSignInDialog = true) => {
    onClick();
    props.onItemClick(closeSignInDialog);
  };

  const renderEntry = ({ key, label, onClick, mailto }) => {
    const propsData = {
      className: 'Entry',
      key,
    };
    let view;
    if (mailto) {
      Object.assign(propsData, {
        href: mailto,
        onClick: onItemClick.bind(this, props.onItemClick),
      });
      view = <a {...propsData}>{label}</a>;
    } else {
      propsData.onClick = onItemClick.bind(this, onClick, key !== 'signIn');
      view = <div {...propsData}>{label}</div>;
    }
    return view;
  };

  const renderSection = (items, isLast) => {
    const classes = ['Section'];
    if (isLast) {
      classes.push('Last');
    }
    return (
      <div className={classes.join(' ')}>
        {items.map(renderEntry)}
        {isLast ? null : <div className="Separator" />}
      </div>
    );
  };

  const signOutUser = () => {
    UsersController.signOutUser(t('SideMenu.loginOutMsg'));
  };

  const showNewsLetterDialog = () => {
    const SubscribeToNewsLetterDialog = lazy(
      () => import('../../SubscribeToNewsLetterDialog/SubscribeToNewsLetterDialog'),
    );
    const modal = <SubscribeToNewsLetterDialog />;
    ModalController.showModal(modal);
  };

  const classes = ['SideMenu'];
  const { className } = props;
  if (className) {
    classes.push(className);
  }
  const style = {
    height: getMiddleSectionHeight(true),
  };

  const header = document.querySelector('.MainDashboardHeader');
  if (header) {
    style.top = header.offsetTop + header.offsetHeight;
  }

  return (
    <div className={classes.join(' ')} style={style}>
      <RenderConditionalComponent ids={[PROPERTY_ID.trentonDucati]}>
        {renderSection(getSection0Items())}
      </RenderConditionalComponent>

      {renderSection(getSection1Items())}
      {renderSection(getSection2Items())}
      {renderSection(getSection3Items())}
      {renderSection(getSection4Items())}
      {renderSection(getSection5Items())}
      {renderSection(getSection6Items(), true)}
    </div>
  );
};

SideMenu.propTypes = {
  className: PropTypes.string,
  browse_config: PropTypes.array,
  onItemClick: PropTypes.func.isRequired
};

export default SideMenu;