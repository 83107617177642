import React, { useEffect, useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import { useSupportService } from '../../context/SupportServiceContext';

function RenderHtmlContent({ htmlContent = {}, dataLabel = {} }) {
  const [state, setState] = useState('');
  const previousContent = useRef();
  const configValues = useSupportService();

  useEffect(() => {
    if (previousContent.current === htmlContent) return; // Skip update if content is the same
    previousContent.current = htmlContent;
    if (!htmlContent || (typeof htmlContent !== 'object' && Object.keys(htmlContent).length === 0)) return;

    let data = htmlContent[dataLabel]?.content || '';
    data = String(data); // Ensure that data is a string

    // Inject <style> tags only, no <script> tags
    setEditTags(data, dataLabel);

    // Remove <style> and <script> tags from content to avoid duplication
    const contentWithoutTags = data.replace(/<style[\s\S]*?>[\s\S]*?<\/style>/gi, '')
      .replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');

    // Replace placeholders using configValues
    const replacePlaceholders = (text) => {
      if (!text) return '';
      Object.entries(configValues).forEach(([key, value]) => {
        text = text.replace(new RegExp(`{${key}}`, 'g'), value);
      });
      return text;
    };

    const htmlText = replacePlaceholders(contentWithoutTags.trim());

    // Sanitize the HTML content with DOMPurify, allowing <style> tags
    const cleanHtmlContent = DOMPurify.sanitize(htmlText, {
      ALLOWED_TAGS: ['style', 'div', 'span', 'p', 'a', 'ul', 'li', 'br', 'button', 'var'],
      ALLOWED_ATTR: ['style', 'href', 'target', 'rel', 'class', 'id', 'data-*', 'var*'],
    });

    console.log('Sanitized HTML:', cleanHtmlContent);

    // Set the sanitized content to state
    setState(cleanHtmlContent);

    return () => {
      // Remove injected <style> tags on component unmount
      const gandolfTags = document.querySelectorAll(`[class*=${dataLabel}-gandolfTags]`);
      Array.from(gandolfTags).forEach(tag => tag.remove());
    };
  }, [htmlContent, dataLabel, configValues]);

  const renderView = () => {
    return <div dangerouslySetInnerHTML={{ __html: state }} />;
  };

  return renderView();
}

export default RenderHtmlContent;

// Helper function to inject <style> tags
const setEditTags = (data, dataLabel) => {
  const hasStyle = data.indexOf('<style>');

  const createTag = (tagName, content) => {
    const className = tagName === 'style' ? 'CSS' : 'JS';
    const tag = document.createElement(tagName);
    tag.setAttribute('class', `${className}-${dataLabel}-gandolfTags`);
    tag.textContent = content;
    document.head.appendChild(tag);
    console.log(`Added ${tagName}:`, content);
  };

  if (hasStyle >= 0) {
    const cssContent = data.slice(data.indexOf('<style>') + 7, data.indexOf('</style>'));
    createTag('style', cssContent);
  }
};