// src/context/SupportServiceContext.js
import React, { createContext, useContext, useState } from 'react';

const SupportServiceContext = createContext();

export const SupportServiceProvider = ({ children }) => {
  const [configValues] = useState({
    site_name: 'Site_Name',
    text_site_url: 'www.siteurl.com',
    merchant_identifier: 'Merchant_Identifier',
    be_a_model_url: 'https://www.model4nakedsword.com',
    local_help_number: '888.207.4534',
    international_help_number: '980.505.8324',
    billing_support_name: 'Epoch',
    billing_support_email: 'billingsupport@email.com',
    billing_support_url: 'https://billing.com/support',
    site_support_email: 'site_support@email.com',
    site_support_url: '/help',
    site_url: 'https://www.siteurl.com',
    help_open: '10AM-8PM ET | MON-FRI',
    help_sat: 'saturday',
    help_sun: 'sunday',
    help_closed: 'closed',
    legal_stars: 'All performers are at least 18 years of age.',
    compliance_link: '/compliance',
    compliance_copy: '"18 U.S.C 2257 Record Keeping Requirements Compliance Statement"',
    billing_support_footer_copy: 'For billing support, please visit our authorized payment processors:',
    sales_support_footer_copy: 'For sales support, visit our authorized sales agents:',
    billing_support_footer_second_biller_copy: 'Segpay User? Please visit our authorized sales agent at',
    billing_support_footer_second_biller_link_copy: 'SEGPAY.com',
    billing_support_footer_second_biller_link: 'https://segpay.com',
    content_removal_footer_copy: 'Content Removal',
    content_removal_link: 'https://aebn.com/contentremoval',
    site_copyright: 'Copyright C',
    site_rights_reserved: 'All Rights Reserved',
    terms_link: '/tos',
    terms_link_copy: 'terms of use',
    faq_help_link: '/help',
    faq_help_link_copy: 'faq & support',
    privacy_policy_link: '/privacy',
    privacy_policy_link_copy: 'privacy policy',
    webmasters_link: 'https://nakedswordcash.com',
    webmasters_link_copy: 'webmasters',
    social_one_link: 'https://instagram.com/naked_sword',
    social_one_link_copy: 'instagram',
    social_two_link: 'https://twitter.com/nakedswordcom',
    social_two_link_copy: 'twitter',
    social_three_link: 'https://www.facebook.com/nakedswordcom',
    social_three_link_copy: 'facebook',
    join_us_link: '/join',
    join_us_link_copy: 'join us',
    login_link: '/sign_in',
    login_link_copy: 'login',
    cookie_policy_link: '/cookie-policy',
    cookie_policy_link_copy: '"Naked Sword Cookie Policy"'
  });

  return (
    <SupportServiceContext.Provider value={configValues}>
      {children}
    </SupportServiceContext.Provider>
  );
};

export const useSupportService = () => useContext(SupportServiceContext);
