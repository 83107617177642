import React from 'react';
import useUserStateChange from '../../hook/useUserStateChange';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { isExternalLink } from '../../services/util-service/util.service';
import { routes } from '../../services/navigation/navigation.service.routes';
import Arrow from '../Arrow/Arrow';
import { DashboardController } from '../../controllers/dashboard-controller/DashboardController';
import './BrowseNavigation.scss';

const BrowseNavigation = (props) => {
  const [logged] = useUserStateChange();
  const { className, baseText } = props;
  const { browse_config = [], stars_page_enabled } = DashboardController.getWhitelabelConfig();

  const renderItem = ({ link, text, open_in_new_window: openInNewWindow, logged_in = true, logged_out = true }) => {
    let view = null;
    let fullLink = link.startsWith('/') ? link : `/${link}`;
    const isExternal = isExternalLink(link);
    const isStarsPage = fullLink === routes.stars;

    if (isExternal) {
      fullLink = link;
    }

    if (isStarsPage && !stars_page_enabled) {
      return view;
    }

    if (!((logged && logged_in) || (!logged && logged_out))) {
      return view;
    }

    const key = `BrowseNavigationLink-${link}`;
    if (openInNewWindow || isExternal) {
      view = (
        <a
          className={'Item'}
          href={fullLink}
          rel={'noopener noreferrer'}
          key={key}
          target={openInNewWindow ? '_blank' : null}
        >
          {text}
        </a>
      );
    } else {
      view = (
        <Link className="Item" key={key} to={fullLink}>
          {text}
        </Link>
      );
    }

    return view;
  };

  if (browse_config?.length === 0) return null;

  return (
    <div className="BrowseNavigation">
      <div className={className}>
        <div className="BrowseNavigationText">{baseText}</div>
        <Arrow />
      </div>
      <div className="dropdown-content">{browse_config?.map(renderItem)}</div>
    </div>
  );
};

export default BrowseNavigation;

BrowseNavigation.propTypes = {
  className: PropTypes.string,
  baseText: PropTypes.string,
};