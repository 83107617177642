import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  displayErrorNotification,
  displayInfoNotification,
} from '../../services/notification-service/notification.service';
import { subscribeToNewsletter } from '../../services/newsletter-service/newsletter.service';
import RenderHtmlContent from '../RenderHtmlContent/RenderHtmlContent';
import { DashboardController } from '../../controllers/dashboard-controller/DashboardController';

import './Footer.scss';

function Footer() {
  const [email, setEmail] = useState('');
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const footerContent = DashboardController.getWhitelabelConfig();

  const onSubscribe = () => {
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailRegex.test(email)) {
        subscribeToNewsletter(email)
          .then(() => {
            displayInfoNotification({
              duration: 3,
              message: t('LegalNotice.subscribeSuccessMsg', { email }),
              title: t('LegalNotice.subscribeSuccess'),
            });
            setEmail('');
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        displayErrorNotification({
          duration: 3,
          message: t('LegalNotice.usernameInvalid'),
          title: t('LegalNotice.invalidEntry'),
        });
        inputRef.current.focus();
      }
    }
  };

  return (
    <div className="Footer">
      <RenderHtmlContent htmlContent={footerContent} dataLabel="footer_links" />

      <div className="NewsletterContainer">
        <span>Stay updated with our newsletter</span>
        <div className="InputContainer">
          <input
            name="email"
            autoComplete="off"
            ref={inputRef}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter E-mail Address Here"
          />
          <button type="button" onClick={onSubscribe}>
            Subscribe
          </button>
        </div>
      </div>

      <RenderHtmlContent htmlContent={footerContent} dataLabel="footer_text" />
    </div>
  );
}

export default Footer;
